import React from 'react';
import Content from '../views/Leasing/Price';

export default ({location}) => <Content location={location} seo={{
  title: 'Vehicle management solution for leasing companies - Pricing',
  lang: 'en',
  description: 'Get pricing for the vehicle management solution powered by OBI+ by filling out the quotation form.',
  meta: [{
    name: 'keywords',
    content: 'Solution for leasing'
  }]
}}/>