import React from 'react';
import i18n from '../../../../locale';
import QuestionTitle from '../QuestionTitle';
import Next from '../Next';
import styles from './styles.module.sass';

export default ({ formData, setFormData, onNext }) => (
  <>
    <QuestionTitle text={`2. ${i18n('How many vehicles do you want connected?')}`}/>
    <div className={styles.container}>
      <input type='number' className={styles.input}
        defaultValue={formData}
        onChange={e => setFormData(Math.floor(e.target.value))}
        placeholder={i18n('Number of vehicles')}/>
    </div>
    <Next onClick={onNext}
      disabled={!formData || formData < 0}/>
  </>
)